<template>
  <div>
    <primaryButton
      class="mr-2"
      :attributesArray="functionElement['attributesArray']"
      type="button"
      :value="functionElement['functionName']"
      @click="show2FA"
      :label="functionElement['functionName']"
      color="save"
      :disabled="!isValid || loading"
      :loading="loading && isValid"
      :icon="'mdi-chevron-right'"
    ></primaryButton>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  props: ["isValid", "loading", "functionElement", "result"],
  components: {
    primaryButton,
  },
  methods: {
    show2FA() {
      //remove all function buttons!
      let functionList = this.result.function;
      for (var i = functionList.length - 1; i >= 0; i--) {
        functionList.splice(i, 1);
      }
      this.$set(this.result, "showQR", true);
      this.$set(this.result.json, "dialogTitle", this.language.componentLabel.titleTFA);
    },
  },
  mounted() {
    //this.$set(this.result.)
  },
};
</script>